import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";

const Trips = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Define table headers
  const headers = [
    { key: "timeZoneId", label: "Time Zone" },
    { key: "createdAt", label: "Created At" },
    { key: "startLocationName", label: "Start Location" },
    { key: "endLocationName", label: "End Location" },
    { key: "uid", label: "UID" },
  ];

  useEffect(() => {
    const fetchTrips = async () => {
      const db = getFirestore();

      try {
        setLoading(true);
        setError("");

        // Query trips collection sorted by creation date (timestamp)
        const tripsQuery = query(collection(db, "trips"), orderBy("createdAt", "desc"));
        const tripsSnapshot = await getDocs(tripsQuery);

        // Map the trips data into an array
        const tripsData = tripsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTrips(tripsData);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch trips. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();
  }, []);

  if (loading) {
    return <div className="p-6 text-center">Loading trips...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto shadow-md rounded-lg p-6">
        <Link to="/">
            <button className="m-10 btn btn-primary">
                Home
            </button>
        </Link>
      <h1 className="text-2xl font-bold mb-6">All Trips</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.key} className="border border-gray-300 px-4 py-2">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {trips.map((trip) => (
              <tr key={trip.id}>
                {headers.map((header) => (
                  <td key={header.key} className="border border-gray-300 px-4 py-2">
                    {header.key === "createdAt"
                      ? trip[header.key]
                        ? new Date(trip[header.key]).toLocaleString()
                        : "N/A"
                      : trip[header.key] || "N/A"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Trips;
