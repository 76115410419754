import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginPage from "pages/Login/Login";
import Dashboard from "pages/Dashboard/Dashboard";
import Users from "pages/Users/Users";
import Trips from "pages/Trips/Trips";
import Chats from "pages/Chats/Chats";

const MASTER_UID = "H0WzCneKdsPW84AwRmFw1nNpvDp2";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isMasterUser, setIsMasterUser] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Check if the logged-in user's UID matches the master UID
        if (user.uid === MASTER_UID) {
          setIsMasterUser(true);
        } else {
          setIsMasterUser(false);
        }
      } else {
        setIsMasterUser(false);
      }
      setLoading(false); // Done checking
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading message while checking
  }

  if (!isMasterUser) {
    return <LoginPage />; // Redirect to login page if not authorized
  }

  return (
    <Router>
      <Routes>
        {/* Default route to Dashboard */}
        <Route path="/" element={<Dashboard />} />
        {/* Route for Users page */}
        <Route path="/users" element={<Users />} />
        <Route path="/trips" element={<Trips />} />
        <Route path="/chats" element={<Chats />} />
        {/* Catch-all route to redirect to Dashboard */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
