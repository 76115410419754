import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const AdminDashboard = () => {
  const [counts, setCounts] = useState({ users: 0, chats: 0, trips: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const fetchCounts = async () => {
    const functions = getFunctions();
    const getAllUsers = httpsCallable(functions, "getAllUsers");
    const db = getFirestore();

    try {
      setLoading(true);
      setError("");

      // Fetch total users using the getAllUsers function
      const usersResult = await getAllUsers();
      const totalUsers = usersResult.data.users.length;

      // Use getDocs to get the count of documents in 'chats' and 'trips'
      const [chatsSnapshot, tripsSnapshot] = await Promise.all([
        getDocs(collection(db, "chats")),
        getDocs(collection(db, "trips")),
      ]);

      // Set counts based on snapshot size
      setCounts({
        users: totalUsers,
        chats: chatsSnapshot.size,
        trips: tripsSnapshot.size,
      });
    } catch (err) {
      console.error(err);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  if (loading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto bg-base-100 shadow-lg rounded-lg p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Total Users Box */}
        <div
          onClick={() => navigate("/users")}
          className="bg-blue-700 text-white p-6 rounded-lg text-center shadow-lg cursor-pointer hover:bg-blue-800 transition"
        >
          <h2 className="text-xl font-semibold">Total Users</h2>
          <p className="text-4xl font-bold">{counts.users}</p>
        </div>

        {/* Total Trips Box */}
        <div
          onClick={() => navigate("/trips")}
          className="bg-yellow-600 text-white p-6 rounded-lg text-center shadow-lg cursor-pointer hover:bg-yellow-700 transition"
        >
          <h2 className="text-xl font-semibold">Total Trips</h2>
          <p className="text-4xl font-bold">{counts.trips}</p>
        </div>

        {/* Total Chats Box */}
        <div
          onClick={() => navigate("/chats")}
          className="bg-green-700 text-white p-6 rounded-lg text-center shadow-lg cursor-pointer hover:bg-green-800 transition"
        >
          <h2 className="text-xl font-semibold">Total Chats</h2>
          <p className="text-4xl font-bold">{counts.chats}</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
